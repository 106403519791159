import { erc20ABI, useWalletClient, useContractRead } from 'wagmi';
import { getContract, switchNetwork } from 'wagmi/actions';
import uwManagerABI from '../../helpers/uwManagerABI';
import { MdContentCopy } from 'react-icons/md';
import { errorToast, successToast } from '../toast';
import { useGetChain } from '../../hooks/useGetChain';
import { MetamaskIcon } from '../metamask/Metamask';
import { Tooltip } from '@mui/material';

export const UWImportErc20Buttons = ({ collection }) => {
  const { data: walletClient } = useWalletClient({
    chainId: parseInt(collection?.chain),
  });
  const { chain, isChainActive } = useGetChain(collection?.chain);

  const { data: dn404Info } = useContractRead({
    chainId: parseInt(collection?.chain),
    abi: uwManagerABI,
    address: chain?.uwManager,
    functionName: 'getWrappedCollection',
    args: [
      collection?.contractType === 'DN404' && collection?.mirrorAddress
        ? collection?.mirrorAddress
        : collection?.address,
    ],
  });

  const handleAddWallet = async () => {
    try {
      if (!isChainActive) await switchNetwork({ chainId: parseInt(collection?.chain) });

      const erc20Contract = getContract({
        abi: erc20ABI,
        address: dn404Info[0],
        walletClient,
        chainId: parseInt(collection?.chain),
      });

      const symbol = await erc20Contract?.read.symbol();
      const decimals = await erc20Contract?.read.decimals();

      await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address: dn404Info[0],
          decimals: Number(decimals),
          symbol,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const copyToClipboard = () => {
    if (!dn404Info[0]) return errorToast('No address to copy');
    navigator.clipboard.writeText(dn404Info[0]);
    successToast('Copied to clipboard!');
  };

  return (
    <div className='flex flex-row items-center justify-center gap-2'>
      <Tooltip title={'Copy ERC20 Token Address'}>
        <button onClick={copyToClipboard} data-testid='copyErc20Address'>
          <MdContentCopy />
        </button>
      </Tooltip>
      <Tooltip title={'Add to wallet ERC20 Token'}>
        <button onClick={handleAddWallet} data-testid='bulkToolapprove'>
          <MetamaskIcon />
        </button>
      </Tooltip>
    </div>
  );
};
